<template>
  <v-dialog v-model="value" :fullscreen="$vuetify.breakpoint.mdAndDown">
    <v-card>
      <v-row align="center" justify="end" no-gutters>
        <v-btn color="info" icon @click="$emit('input', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="12" md="6" xl="6" lg="6">
          <v-row align="center" justify="center" no-gutters>
            <v-img :src="cliente.media_url" width="500" height="500" contain>
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="info"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-row>
        </v-col>
        <v-col cols="11" md="5" xl="5" lg="5">
          <span class="body-1 title--text font-weight-light">
            {{ cliente.caption }}
          </span>
        </v-col>
      </v-row>
      <br />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'clienteDetalle',
  props: {
    cliente: {
      type: Object,
      default: () => {
        return {};
      },
    },
    value: {
      type: Boolean,
    },
  },
};
</script>

<style scoped></style>
